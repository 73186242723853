.vacaionModal {
  .modal-title {
    width: 100%;
  }

  .modal-content {
    height: max-content !important;
  }
}

.custom__select {
  input {
    text-align: right;
  }
}
