.clockin__calendar {
  .fc-header-toolbar {
    margin-bottom: 0 !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  .fc-view-harness {
    display: none !important;
  }
  .fc-toolbar-title {
    margin-right: 1rem !important;
  }
  .fc-toolbar-chunk {
    margin-bottom: 0 !important;
  }
}
