.activity__card__item {
  animation: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: var(--bs-secondary);
    .delete__icon {
      display: block;
    }
  }
  .delete__icon {
    @media (min-width: 900px) {
      display: none;
    }
  }
}
