.home_card {
  // box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
  //   rgba(0, 0, 0, 0.15) 0px -10px 20px 0px inset, rgba(0, 0, 0, 0.1) 0px -30px 20px 0px inset,
  //   rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
  //   rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
  //   rgba(0, 0, 0, 0.09) 0px 32px 16px;
  // box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
  //   rgba(0, 0, 0, 0.2) 0px 2px 4px;
  // box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
  //   rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border: 1px solid #cecece;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px 0px;
  transition: all 0.3s ease-in-out;
  color: #000;

  .icon {
    color: var(--bs-primary) !important;
  }
  &:hover {
    background-color: var(--bs-primary);
    cursor: pointer;
    color: var(--bs-gray-300);
    *,
    .icon {
      color: var(--bs-gray-300) !important;
    }
  }

  .icon {
    font-size: 2rem;
  }
}
