@media (max-width: 1200px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
  .rdrStaticRanges {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}
body::-webkit-scrollbar {
  width: 0.7em !important;
}

body::-webkit-scrollbar-track {
  background: #c3c3c3 !important;
}
:root,
div {
  scrollbar-color: var(--bs-primary) #c3c3c3 !important;
  scrollbar-width: thin !important;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary) !important;
  outline: 1px solid #c3c3c3 !important;
}
div::-webkit-scrollbar {
  width: 0.7em !important;
}

div::-webkit-scrollbar-track {
  background: #757575 !important;
}

div::-webkit-scrollbar-thumb {
  background-color: var(--color-primary) !important;
  outline: 1px solid slategrey !important;
}

.disabled__btn {
  background-color: #c9c9c9 !important;
  &:hover {
    background-color: #c9c9c9 !important;
  }
}
