.shiftModal .modal-content {
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
  bottom: 0;
  border-radius: 1rem 1rem 0 0 !important;
  height: max-content !important;
}

.shiftModal.modal.fade .modal-dialog {
  transform: translate(0, 100%) scaleY(0) !important;
}
.shiftModal.modal.fade.show .modal-dialog {
  transform: translate(0, 0) scaleY(1) !important;
  display: flex !important;
  align-items: stretch !important;
}
