.back__arrow {
  @media (max-width: 1200px) {
    left: -1rem;
  }
}

.next__arrow {
  @media (max-width: 1200px) {
    right: -1rem;
  }
}

.slide {
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}
.slider__section {
  width: 100%;
  height: 100%;
  margin-top: 2rem;

  .sports__rights__small-card:not(&:last-child) {
    margin-right: 0rem;
    @media (max-width: 1200px) {
      margin-right: 0;
    }
  }
}

.slick-dots li button::before,
.slick-dots li.slick-active button::before {
  font-size: 1.6rem !important;
  color: var(--color-primary) !important;
}

.slick-dots {
  @media (max-width: 1200px) {
    bottom: -25px !important;
  }

  li {
    @media (max-width: 1200px) {
      margin: 0 !important;
    }
  }
}
